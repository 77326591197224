<template>
  <div @click="changeAudioPlay($event)" class="music-control">
    <FriendGet style="margin-top: 0.46rem" :width="4.77" :nameHeight="0.4" :color="'#fff'" />
    <div class="music-control-content">
      <div class="content-top">
        <div class="content-top-left">
          <span
            :data-clipboard-text="mediaInfo.invite_code"
            @click="openApp('详情页', '#music-name', '歌曲名')"
            id="music-name"
            class="music-name"
          >
            {{ musicInfo.is_valid === '1' ? musicInfo.not_valid_txt : musicInfo.title }}</span
          >
          <div class="aviator-focus-wrap">
            <div
              :data-clipboard-text="mediaInfo.invite_code"
              @click="openApp('详情页', '#focus-dom', '关注')"
              id="focus-dom"
              class="aviator-focus-warp-top"
            >
              <div id="focus-dom-warp" class="aviator-focus-warp-aviator">
                <div id="focus-dom-warp-bg" class="aviator-warp-bg"></div>
                <img id="focus-dom-warp-img" :src="musicInfo.avatar_small" class="aviator-warp-img__aviator" alt="" />
                <img
                  id="focus-dom-warp-img-add"
                  class="aviator-warp-img__add"
                  src="@/assets/images/share-content/add.png"
                  alt="快音"
                />
                <span id="focus-dom-warp-focus" class="aviator-warp-focus">关注</span>
              </div>
              <div
                :data-clipboard-text="mediaInfo.invite_code"
                @click="openApp('详情页', `#popular_top`, '榜单分类')"
                id="popular_top"
                class="popular-container"
              >
                <div v-for="(item, k) in musicInfo.tags" :key="k" class="popular-warp">
                  <div class="popular-warp-name">{{ item }}</div>
                  <div class="popular_bg"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="set-look-warp">
            <div
              :data-clipboard-text="mediaInfo.invite_code"
              @click="openApp('详情页', '#set-ring', '彩铃')"
              id="set-ring"
              class="warp"
            >
              <img id="set-ring-img" src="@/assets/images/share-content/ling.png" alt="icon" />
              <span id="set-ring-info">设为彩铃</span>
            </div>
            <div
              :data-clipboard-text="mediaInfo.invite_code"
              @click="openApp('详情页', '#look-song', '歌词')"
              id="look-song"
              style="margin-left: 0.24rem"
              class="warp"
            >
              <img id="look-song-img" src="@/assets/images/share-content/ci.png" alt="icon" />
              <span id="look-song-info">查看歌词</span>
            </div>
          </div>
        </div>
        <div class="content-top-right">
          <div
            @click="openApp('详情页', '#music-like', '点赞数')"
            :data-clipboard-text="mediaInfo.invite_code"
            id="music-like"
            class="discuss-like-warp-item"
          >
            <img
              id="music-like-img"
              class="right_img like_img"
              src="@/assets/images/share-content/like.png"
              alt="快音"
            />
            <span id="music-like-info">{{ musicInfo.show_likes }}</span>
          </div>
          <div
            @click="openApp('详情页', '#music-dis', '评论数')"
            :data-clipboard-text="mediaInfo.invite_code"
            id="music-dis"
            class="discuss-like-warp-item"
          >
            <img id="music-dis-img" class="right_img" src="@/assets/images/share-content/discuss.png" alt="快音" />
            <span id="music-dis-info">{{ musicInfo.comments }}</span>
          </div>
          <div
            @click="openApp('详情页', '#music-more', '更多')"
            :data-clipboard-text="mediaInfo.invite_code"
            id="music-more"
            class="discuss-like-warp-item"
          >
            更多
          </div>
        </div>
      </div>
      <div class="content-bot">
        <div class="handle-warp">
          <div
            @click="openApp('互动', '#btn-sing', '去唱')"
            :data-clipboard-text="mediaInfo.invite_code"
            id="btn-sing"
            class="handle-warp-left"
          >
            <img id="btn-sing-img" src="@/assets/images/share-content/mc-phone.png" alt="icon" />
            <span id="btn-sing-info">去唱这首歌</span>
          </div>
          <div class="handle-warp-right">
            <img @click.stop.prevent="$refs.EmojiWarp.thumbUpClick(likeEmo, '喜欢')" :src="likeEmo" alt="icon" />
            <img @click.stop.prevent="$refs.EmojiWarp.thumbUpClick(xinEmo, '爱心')" :src="xinEmo" alt="icon" />
            <img @click.stop.prevent="$refs.EmojiWarp.thumbUpClick(flowerEmo, '玫瑰')" :src="flowerEmo" alt="icon" />
            <img
              @click.stop.prevent="$refs.EmojiWarp.thumbUpClick(goodEmo, '拇指')"
              style="margin-right: 0.6rem"
              src="@/assets/images/share-content/good-emo.png"
              alt="icon"
            />
            <div @click.stop.prevent="$refs.EmojiWarp.thumbUpClick('', '点赞')" class="dis-like">
              <img src="@/assets/images/share-content/dianzan-emo.png" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control-warp">
      <template>
        <div v-if="!isAudioPlay" class="control-warp-bg"></div>
        <img v-if="!isAudioPlay" class="music-dire" src="@/assets/images/share-content/stop.png" alt="快音" />
      </template>
    </div>

    <EmojiContent ref="EmojiWarp" />
    <MusicMedia />
  </div>
</template>

<script>
  import FriendGet from '@/views/Welfare/Fission/ContentLanding/components/FriendGet';
  import EmojiContent from '@/views/Welfare/Fission/ContentLanding/components/EmojiContent';
  import MusicMedia from '@/views/Welfare/Fission/ContentLanding/components/MusicMedia';
  import { mapState } from 'vuex';

  export default {
    name: 'MusicControl',
    components: { FriendGet, EmojiContent, MusicMedia },
    props: {
      openApp: {
        type: Function,
        default: () => {},
      },
      isAudioPlay: {
        type: Boolean,
        default: false,
      },
      changeAudioPlay: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        likeEmo: require('@/assets/images/share-content/like-emo.png'),
        xinEmo: require('@/assets/images/share-content/xin-emo.png'),
        flowerEmo: require('@/assets/images/share-content/flower-emo.png'),
        goodEmo: require('@/assets/images/share-content/good-emo.png'),
        thumbEmo: require('@/assets/images/share-content/dianzan-emo.png'),
      };
    },
    computed: {
      ...mapState('share/contentLanding', ['musicInfo', 'mediaInfo']),
    },
    mounted() {
      // 歌曲没有封面图片时埋点
      if (!this.musicInfo.background) {
        this.$track({ page_title: '内容落地页迭代', element_name: '无图' });
      }
    },
  };
</script>

<style lang="less">
  .emo-drop-warp {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .img {
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      left: 5rem;
      z-index: 4;
    }
  }
  @keyframes emoOneAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(80deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(0.5rem, 6rem) rotate(138deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(1rem, 9.4rem) rotate(180deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(1.2rem, 8rem) rotate(226deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(1.5rem, 9.4rem) rotate(280deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(1.7rem, 8.8rem) rotate(313deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(1.9rem, 9.4rem) rotate(360deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(2rem, 9.2rem) rotate(400deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(2.1rem, 9.4rem) rotate(430deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoTwoAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(-94deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(-0.5rem, 6rem) rotate(-178deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(-0.8rem, 9.4rem) rotate(-200deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(-1rem, 7.3rem) rotate(-246deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(-1.3rem, 9.4rem) rotate(-280deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(-1.6rem, 8.5rem) rotate(-323deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(-1.8rem, 9.4rem) rotate(-370deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(-1.9rem, 9rem) rotate(-400deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(-2rem, 9.4rem) rotate(-430deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoThreeAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(30deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(0.5rem, 6.5rem) rotate(88deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(0.8rem, 9.4rem) rotate(130deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(1rem, 8.5rem) rotate(180deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(1.3rem, 9.4rem) rotate(230deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(1.6rem, 8.9rem) rotate(293deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(1.8rem, 9.4rem) rotate(350deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(1.9rem, 9.2rem) rotate(390deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(2rem, 9.4rem) rotate(450deg);
      animation-timing-function: ease-out;
    }
  }
  @keyframes emoFourAni {
    0% {
      transform: translate(0, 0) rotate(0deg);
      animation-timing-function: ease-in;
    }

    10% {
      transform: translate(0, 9.4rem) rotate(-84deg);
      animation-timing-function: ease-out;
    }

    30% {
      transform: translate(-0.5rem, 6.5rem) rotate(-128deg);
      animation-timing-function: ease-in;
    }
    45% {
      transform: translate(-0.6rem, 9.4rem) rotate(-180deg);
      animation-timing-function: ease-out;
    }

    60% {
      transform: translate(-1rem, 7.3rem) rotate(-226deg);
      animation-timing-function: ease-in;
    }
    70% {
      transform: translate(-1.3rem, 9.4rem) rotate(-270deg);
      animation-timing-function: ease-out;
    }

    80% {
      transform: translate(-1.6rem, 8rem) rotate(-303deg);
      animation-timing-function: ease-in;
    }

    88% {
      transform: translate(-1.8rem, 9.4rem) rotate(-340deg);
      animation-timing-function: ease-out;
    }
    95% {
      transform: translate(-1.9rem, 9.2rem) rotate(-370deg);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translate(-2rem, 9.4rem) rotate(-420deg);
      animation-timing-function: ease-out;
    }
  }
</style>

<style lang="less" scoped>
  @cardBackground: rgba(0, 0, 0, 0.3);
  .music-control {
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    .control-warp {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-0.8rem, -0.8rem);
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      z-index: 3;
      .control-warp-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        border-radius: 50%;
        opacity: 0.5;
      }
      .music-dire {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-0.6rem, -0.6rem);
        width: 1.2rem;
        height: 1.2rem;
        z-index: 1;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 0.46rem 0.3rem 0.2rem;
      box-sizing: border-box;
      z-index: 2;
      .content-top {
        display: flex;
        flex: 1;
        &-right {
          width: 0.8rem;
          font-size: 0.24rem;
          .discuss-like-warp-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: rgb(255, 255, 255);
            margin-top: 0.2rem;
            font-size: 0.24rem;
            span {
              font-size: 0.24rem;
              text-align: center;
            }
            .like_img {
              animation: breathing 5s linear infinite;
            }
            .right_img {
              width: 0.48rem;
              height: 0.48rem;
            }
          }
        }
        &-left {
          flex: 1;
          .music-name {
            margin-top: 0.8rem;
            display: inline-block;
            width: 5.6rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.4rem;
            color: #fff;
            font-weight: bold;
          }
          .aviator-focus-wrap {
            color: #fff;
            margin-top: 0.1rem;
            width: 5.6rem;
            .aviator-focus-warp-top {
              display: flex;
              align-items: center;

              .aviator-focus-warp-aviator {
                width: 1.2rem;
                height: 0.4rem;
                display: flex;
                align-items: center;

                color: #fff;
                .aviator-warp-img__aviator {
                  width: 0.32rem;
                  height: 0.32rem;
                  z-index: 1;
                  border-radius: 50%;
                  margin-left: 0.04rem;
                }
                .aviator-warp-img__add {
                  width: 0.2rem;
                  height: 0.2rem;
                  z-index: 1;
                }
                .aviator-warp-focus {
                  z-index: 1;
                  font-size: 0.2rem;
                }
                .aviator-warp-bg {
                  border-radius: 0.2rem;
                  background: @cardBackground;
                  position: absolute;
                  width: 1.2rem;
                  height: 0.4rem;
                }
              }
              .popular-container {
                display: flex;
                align-items: center;
                overflow: hidden;
                flex: 1;
              }
              .popular-warp {
                width: 0.88rem;
                height: 0.32rem;
                flex-shrink: 0;
                font-size: 0.2rem;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.2rem;
                .popular_bg {
                  background: @cardBackground;
                  position: absolute;
                  top: 0;
                  width: 0.88rem;
                  height: 0.32rem;
                  border-radius: 0.06rem;
                }
                .popular-warp-name {
                  z-index: 1;
                }
              }
            }
          }
          .set-look-warp {
            display: flex;
            margin-top: 0.3rem;
            .warp {
              width: 1.64rem;
              height: 0.56rem;
              border-radius: 0.28rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.24rem;
              color: #fff;
              font-weight: bold;
              background: @cardBackground;
              img {
                width: 0.32rem;
                height: 0.32rem;
                margin-right: 0.1rem;
              }
            }
          }
        }
      }
      .content-bot {
        height: 1rem;
        margin-top: 0.2rem;
        .handle-warp {
          height: 0.74rem;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 0.37rem;
          //filter: blur(15px);
          display: flex;
          padding: 0.14rem 0;
          box-sizing: border-box;
          &-left {
            width: 2.56rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.28rem;
            color: #fff;
            font-weight: bold;
            border-right: 0.02rem solid #fff;
            img {
              width: 0.32rem;
              height: 0.32rem;
              margin-right: 0.14rem;
            }
          }
          &-right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .dis-like {
              z-index: 1;
              width: 0.86rem;
              height: 0.86rem;
              background: rgba(255, 255, 255, 0.75);
              display: flex;
              align-items: center;
              border-radius: 50%;
              position: absolute;
              right: 0;
              img {
                width: 0.7rem;
                height: 0.7rem;
              }
            }
            img {
              z-index: 1;
              width: 0.5rem;
              height: 0.5rem;
              margin: 0 0.1rem;
            }
          }
        }
      }
    }
  }
</style>
