var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"music-control",on:{"click":function($event){return _vm.changeAudioPlay($event)}}},[_c('FriendGet',{staticStyle:{"margin-top":"0.46rem"},attrs:{"width":4.77,"nameHeight":0.4,"color":'#fff'}}),_c('div',{staticClass:"music-control-content"},[_c('div',{staticClass:"content-top"},[(_vm.LyricsList.length > 0)?_c('div',{staticClass:"item-warp"},_vm._l((_vm.LyricsList),function(item,k){return _c('div',{key:k,class:['lyric-item', _vm.currentIndex === k && 'active'],style:({ top: (((k + 1 - _vm.currentIndex) * 0.6) + "rem") })},[_vm._v(" "+_vm._s(item.lyric)+" ")])}),0):_c('div',{staticClass:"music-name"},[_vm._v(" "+_vm._s(_vm.musicInfo.is_valid === '1' ? _vm.musicInfo.not_valid_txt : _vm.musicInfo.title)+" ")])]),_c('div',{staticClass:"content-bot"},[_c('div',{staticClass:"lyric-song"},[_c('div',{staticClass:"lyric-btn",attrs:{"data-clipboard-text":_vm.MusicContentAb === 'c' && _vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'look-song' : 'lyric-btn'},on:{"click":function($event){_vm.MusicContentAb === 'c'
              ? _vm.openApp('详情页曝光', '#look-song', '完整歌词')
              : _vm.handleModelV2(true, '详情页曝光', '完整歌词')}}},[_c('img',{attrs:{"id":"look-song-img","src":require("@/assets/images/share-content/ci.png"),"alt":""}}),_vm._v(" 查看完整歌词 ")]),_c('div',{staticClass:"lyric-btn",attrs:{"data-clipboard-text":_vm.MusicContentAb === 'c' && _vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'set-ring' : 'lyric-btn-one'},on:{"click":function($event){_vm.MusicContentAb === 'c'
              ? _vm.openApp('详情页曝光', '#set-ring', '彩铃')
              : _vm.handleModelV2(true, '详情页曝光', '彩铃')}}},[_c('img',{attrs:{"id":"set-ring-img","src":require("@/assets/images/share-content/ling.png"),"alt":""}}),_vm._v(" 设为彩铃 ")])]),_c('div',{staticClass:"download-btn",attrs:{"data-clipboard-text":_vm.MusicContentAb === 'c' && _vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'download-btn-v3' : 'download-btn-pri'},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.preventDefault();_vm.MusicContentAb === 'c'
            ? _vm.openApp('详情页曝光', '#download-btn-v3', '下载')
            : _vm.handleModelV2(true, '详情页曝光', '下载')}}},[_vm._v(" 下载歌曲 ")])]),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"portrait",attrs:{"data-clipboard-text":_vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'focus-dom' : 'portrait'},on:{"click":function($event){_vm.MusicContentAb === 'c'
            ? _vm.openApp('详情页曝光', '#focus-dom', '头像')
            : _vm.handleModelV2(true, '详情页曝光', '头像')}}},[_c('img',{attrs:{"id":"avatar-img","src":_vm.musicInfo.avatar_small,"alt":"avatar"}}),_vm._m(0)]),_c('div',{staticClass:"handle-content"},[_c('div',{staticClass:"handle-item",attrs:{"data-clipboard-text":_vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'music-like' : 'handle-item-one'},on:{"click":function($event){_vm.MusicContentAb === 'c'
              ? _vm.openApp('详情页曝光', '#music-like', '喜欢')
              : _vm.handleModelV2(true, '详情页曝光', '喜欢')}}},[_c('img',{attrs:{"id":"music-like-img","src":require("@/assets/images/share-content/like.png"),"alt":""}}),_c('span',{staticClass:"info",attrs:{"id":"music-like-info"}},[_vm._v(_vm._s(_vm.musicInfo.show_likes))])]),_c('div',{staticClass:"handle-item",attrs:{"data-clipboard-text":_vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'music-dis' : 'handle-item-two'},on:{"click":function($event){_vm.MusicContentAb === 'c'
              ? _vm.openApp('详情页曝光', '#music-dis', '评论')
              : _vm.handleModelV2(true, '详情页曝光', '评论')}}},[_c('img',{attrs:{"id":"music-dis-img","src":require("@/assets/images/share-content/discuss.png"),"alt":""}}),_c('span',{staticClass:"info",attrs:{"id":"music-dis-info"}},[_vm._v(_vm._s(_vm.musicInfo.comments))])]),_c('div',{staticClass:"handle-item",attrs:{"data-clipboard-text":_vm.mediaInfo.invite_code,"id":_vm.MusicContentAb === 'c' ? 'music-more' : 'handle-item-three'},on:{"click":function($event){_vm.MusicContentAb === 'c'
              ? _vm.openApp('详情页曝光', '#music-more', '更多')
              : _vm.handleModelV2(true, '详情页曝光', '更多')}}},[_c('span',{staticClass:"info",attrs:{"id":"music-more-info"}},[_vm._v("更多")])]),_c('div',{staticClass:"handle-item",on:{"click":function($event){return _vm.$refs.EmojiWarp.thumbUpClick('', '点赞')}}},[_c('img',{attrs:{"id":"emoji-img","src":require("@/assets/images/share-content/dianzan-emo.png"),"alt":""}}),_c('span',{staticClass:"info",attrs:{"id":"emoji-info"}},[_vm._v("赞赏")])])])])]),_c('div',{staticClass:"control-warp"},[[(!_vm.isAudioPlay)?_c('div',{staticClass:"control-warp-bg"}):_vm._e(),(!_vm.isAudioPlay)?_c('img',{staticClass:"music-dire",attrs:{"src":require("@/assets/images/share-content/stop.png"),"alt":"快音"}}):_vm._e()]],2),_c('EmojiContent',{ref:"EmojiWarp",attrs:{"handleModelV2":_vm.handleModelV2}}),_c('MusicMedia')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add",attrs:{"id":"avatar-add"}},[_c('img',{staticClass:"aviator-warp-img__add",attrs:{"src":require("@/assets/images/share-content/add.png"),"alt":"快音"}})])}]

export { render, staticRenderFns }