<template>
  <div class="friend">
    <div
      :style="{
        background: friendGetList.length > 0 ? 'rgb(135, 135, 135,0.3)' : '',
        height: `${nameHeight}rem`,
        fontSize: `${fontSize}rem`,
        width: `${width}rem`,
        color: `${color}`,
      }"
      class="friend-name"
    >
      <div
        :class="['friend-name-item', isNeedTransition ? 'item-transition' : '']"
        :style="{ top: `-${friendItemTopDistance}rem`, height: `${nameHeight}rem`, lineHeight: `${nameHeight}rem` }"
        v-for="(item, k) in friendGetList"
        :key="k"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'FriendGet',
    computed: {
      ...mapState('share/contentLanding', ['musicInfo', 'mediaInfo']),
    },
    props: {
      nameHeight: {
        type: Number,
        default: 0.5,
      },
      fontSize: {
        type: Number,
        default: 0.24,
      },
      width: {
        type: Number,
        default: 3.8,
      },
      color: {
        type: String,
        default: '#339fff',
      },
    },
    data() {
      return {
        friendGetList: [],
        friendItemTopDistance: 0,
        isNeedTransition: true,
        reSetScrollFlag: 0,
        scrollTimer: 0,
      };
    },
    beforeMount() {
      this.reSetScrollFlag = this.mediaInfo.marquee.length;
      if (this.reSetScrollFlag > 0) {
        this.friendGetList = [...this.mediaInfo.marquee, this.mediaInfo.marquee[0]];
        this.friendItemScroll();
      }
    },
    methods: {
      /**
       * @description 好友获得滚动
       */
      friendItemScroll() {
        let interval = setInterval(() => {
          if (this.reSetScrollFlag === this.scrollTimer) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复滚动区初始位置
            this.friendItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.friendItemTopDistance += this.nameHeight;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.friendItemTopDistance += this.nameHeight;
          this.scrollTimer++;
        }, 1500);
        this.$once('hook:beforeDestroy', () => {
          clearInterval(interval);
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .friend {
    display: flex;
    align-items: center;
    z-index: 2;
    &-start {
      width: 1.08rem;
      height: 0.32rem;
    }
    &-name {
      //width: 3.8rem;
      color: #339fff;
      //font-size: 0.24rem;
      font-weight: 600;
      border-radius: 0.25rem;
      overflow: hidden;
      //height: 0.5rem;
      margin: 0 0.2rem;

      &-item {
        padding: 0 0.2rem;
        box-sizing: border-box;
        position: relative;
        text-align: center;
        //height: 0.5rem;
        //display: flex;
        //justify-content: center;
        //align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &-info {
          max-width: 2rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.item-transition {
          transition: top 1s;
        }
      }
    }
  }
</style>
