<template>
  <div class="music-media">
    <video
      v-if="musicInfo.play_url"
      x-webkit-airplay="true"
      x5-video-player-type="h5"
      x5-video-orientation="portraint"
      x5-video-player-fullscreen="true"
      webkit-playsinline
      playsinline
      id="video_dom"
      ref="video_dom"
      :poster="musicInfo.video_cover || musicInfo.background"
      class="music-control-mv"
      loop
      preload
    >
      <source :src="musicInfo.play_url" type="video/mp4" />
    </video>
    <img
      v-if="musicInfo.is_valid === '0'"
      class="music_img"
      :src="musicInfo.video_cover || musicInfo.background"
      alt=""
    />
    <audio preload v-if="!musicInfo.play_url" loop ref="music_dom" id="music_dom" :src="musicInfo.cdn_url">
      你的浏览器暂不支持audio标签
    </audio>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'MusicMedia',
    props: {
      changeAudioPlay: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapState('share/contentLanding', ['musicInfo']),
    },
  };
</script>

<style scoped lang="less">
  .music-media,
  .music-control-mv {
    position: absolute;
    top: 0;
    height: 10rem;
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .music_img {
    position: absolute;
    top: 0;
    height: 10rem;
    width: 100%;
    z-index: 0;
    object-fit: cover;
  }
</style>
