<template>
  <div @click.self="changeAudioPlay" class="music-control">
    <div class="music-control-content">
      <img
        :class="[`music-control-content__pointer`, isAudioPlay ? `play-music-animation` : 'stop-music-animation']"
        src="@/assets/images/share-content/pointer.png"
        alt="快音"
      />
      <img
        :class="[`music-control-content__disc`, isAudioPlay ? `spin_ani` : ``]"
        src="@/assets/images/share-content/disc-image.png"
        alt="快音"
      />
      <img
        @click="changeAudioPlay(true)"
        v-if="!isAudioPlay"
        class="music-control-content__handler"
        src="@/assets/images/share-content/stop.png"
        alt="快音"
      />
      <img
        @click="changeAudioPlay(false)"
        v-else
        class="music-control-content__handler"
        src="@/assets/images/share-content/start.png"
        alt="快音"
      />
      <div class="music-control-content__bg">
        <div class="music-control-content__transparent"></div>
      </div>
      <div :class="[`music_bg`, isAudioPlay ? `spin_ani` : ``]">
        <img
          v-if="musicInfo.is_valid === '0'"
          class="music_img"
          :src="musicInfo.cover || musicInfo.avatar_url"
          alt=""
        />
      </div>
    </div>
    <div class="music-info-name">
      {{ musicInfo.is_valid === '1' ? musicInfo.not_valid_txt : musicInfo.title }}
    </div>
    <div class="music-info-author">
      {{ musicInfo.is_valid === '1' ? '' : musicInfo.nickname }}
    </div>
    <div
      @click="openApp('去唱这首歌', '#btn-sing')"
      :data-clipboard-text="mediaInfo.invite_code"
      id="btn-sing"
      class="music-sing-btn"
    >
      <div class="music-sing-btn-bg"></div>
      <div class="music-sing-btn-cont">
        <img class="music-sing-btn-mic-img" src="@/assets/images/share-content/mc-phone.png" alt="快音" />
        <div>去唱这首歌</div>
      </div>
    </div>
    <audio id="music_dom" loop ref="music_dom" :src="musicInfo.cdn_url">你的浏览器暂不支持audio标签</audio>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'MusicControl-v1',
    props: {
      openApp: {
        type: Function,
        default: () => {},
      },
      isAudioPlay: {
        type: Boolean,
        default: false,
      },
      changeAudioPlay: {
        type: Function,
        default: () => {},
      },
    },

    computed: {
      ...mapState('share/contentLanding', ['musicInfo', 'mediaInfo']),
    },
  };
</script>

<style lang="less" scoped>
  .music-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .music-control-content {
      width: 3.08rem;
      height: 3.08rem;
      position: relative;
      margin-top: 1.06rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .music-control-content__pointer {
        width: 1.66rem;
        height: 1rem;
        position: absolute;
        top: -0.6rem;
        right: -0.2rem;
        z-index: 9;
      }
      .play-music-animation {
        transform-origin: left top;
        animation: pointerOPen 5s linear infinite;
      }
      .stop-music-animation {
        animation: pointerStop 2s linear 1;
      }
      .music-control-content__disc {
        width: 3.08rem;
        height: 3.08rem;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      .music-control-content__handler {
        width: 0.6rem;
        height: 0.6rem;
        z-index: 90;
      }
      .music-control-content__bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        .music-control-content__transparent {
          width: 0.86rem;
          height: 0.86rem;
          background: #000;
          opacity: 0.5;
          border-radius: 50%;
        }
      }
      .music_bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .music_img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }
      }
      .spin_ani {
        animation: spin 10s linear infinite;
      }
    }
    .music-info-name {
      font-size: 0.36rem;
      font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
      font-weight: bold;
      color: #f0f0f0;
      line-height: 0.5rem;
    }
    .music-info-author {
      line-height: 0.5rem;
      font-size: 0.24rem;
      font-family: PingFang-SC-Bold, PingFang-SC, sans-serif;
      font-weight: 500;
      color: #f0f0f0;
    }
    .music-sing-btn {
      width: 2.56rem;
      height: 0.64rem;
      position: relative;
      margin-top: 0.2rem;
      .music-sing-btn-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #fff;
        border-radius: 0.34rem;
      }
      .music-sing-btn-cont {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 0.32rem;
        .music-sing-btn-mic-img {
          width: 0.36rem;
          height: 0.36rem;
          margin-right: 0.16rem;
        }
      }
    }
    @keyframes pointerOPen {
      0% {
        transform: rotate(30deg);
      }
      50% {
        transform: rotate(25deg);
      }
      100% {
        transform: rotate(30deg);
      }
    }
    @keyframes pointerStop {
      100% {
        transform: rotate(0deg);
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
        transition: all 5s;
      }
    }
  }
</style>
